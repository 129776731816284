import React, { useEffect, useState } from "react";
import { setLoginDetails } from "../../Actions/Actions";
import { connect } from "react-redux";
import axios from "axios";
import { serverLink } from "../../Resources/Url";
import { useNavigate } from "react-router-dom";
import HomeContentLoader from "../Shared/Loaders/HomeContentLoader/HomeContentLoader";
import { Modal } from "react-st-modal";
import SubmittingLoader from "../Shared/SubmittingLoader/SubmittingLoader";

const LiveStreaming = () => {
  const [isSubmittingLoader, setIsSubmittingLoader] = useState(false);
  const navigate = useNavigate();
  const [project, setProject] = useState([]);
  const key = "AIzaSyDl8RhIqpgtoDTPjTghGyyUMsxOq3clV0s";
  const channelUrl = window.location.pathname;
  const parts = channelUrl.split("/");
  const channelId = parts[2];
  const projectId = parts[3];
  const [videos, setVideos] = useState([]);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [pageReloaded, setPageReloaded] = useState(false);
  const liveFeedKey = localStorage.getItem("LiveFeedKey");

  const [createItem, setCreateItem] = useState({
    LiveFeedKey: "",
    LiveFeedKeyId: "",
  });

  const onEdit = async (e) => {
    const id = e.target.id;
    const value = e.target.value;

    setCreateItem({
      ...createItem,
      [id]: value,
    });
  };

  function closeModal() {
    setIsOpen(false);
  }

  function closeLiveFeedPanel() {
    window.location.href = "https://www.nalda.gov.ng";
  }

  const submitLiveFeedKey = async (e) => {
    e.preventDefault();
    setIsSubmittingLoader(true);

    for (let key in createItem) {
      if (createItem.hasOwnProperty(key) && key !== "LiveFeedKeyId") {
        if (createItem[key] === "") {
          setTimeout(async () => {
            setIsSubmittingLoader(false);
            window.alert(`Please enter farm live feed key`);
          }, 1000);
          return false;
        }
      }
    }

    setTimeout(async () => {
      if (createItem.LiveFeedKey === project.LiveFeedKey) {
        const key = createItem.LiveFeedKey;
        localStorage.setItem("LiveFeedKey", JSON.stringify(key));
        setIsSubmittingLoader(false);
        closeModal();
        window.location.reload();
      } else {
        window.alert(`Invalid Live Feed Key!`);
        setIsSubmittingLoader(false);
      }
    }, 2000);
  };

  const onAttemptClose = () => {
    window.alert(`Please enter live feed key!`);
  };

  const onCancel = async () => {
    window.location.href = "https://www.nalda.gov.ng";
  };

  const sendData = {
    ProjectId: Number(projectId),
  };

  useEffect(() => {
    if (!pageReloaded && liveFeedKey && liveFeedKey.length === 0) {
      setPageReloaded(true);
      window.location.reload();
    }

    if (liveFeedKey === null || liveFeedKey.length === 0) {
      setIsOpen(true);
    }

    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://www.googleapis.com/youtube/v3/search?key=${key}&channelId=${channelId}&eventType=live&type=video&part=snippet`
        );
        const data = await response.json();
        setVideos(data.items);
      } catch (error) {
        console.error("Error fetching YouTube data:", error);
      }
    };

    const fetchProject = async () => {
      try {
        const response = await axios.post(
          `${serverLink}shared/projects`,
          sendData
        );

        setProject(response.data);
      } catch (error) {
        navigate("/");
        console.error("Error fetching product:", error);
      }
    };

    fetchProject().then((r) => {});
    fetchData().then((r) => {});
  }, [liveFeedKey]);

  if (Object.keys(project).length === 0) {
    return <HomeContentLoader />;
  }

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Live YouTube Feed</h1>
      <div style={styles.videoContainer}>
        {videos.map((video) => (
          <div key={video.id.videoId} style={styles.videoWrapper}>
            <iframe
              title={video.snippet.title}
              src={`https://www.youtube.com/embed/${video.id.videoId}`}
              frameBorder="0"
              allowFullScreen
              style={styles.video}
            ></iframe>
            <h2 style={styles.videoTitle}>{video.snippet.title}</h2>
          </div>
        ))}
      </div>

      <div
        id="tpReturnCustomerLoginForm"
        className="tp-return-customer"
        style={{
          position: "relative",
          zIndex: "9999",
        }}
      >
        {modalIsOpen && (
          <div
            className="tp-modal-overlay"
            style={{
              color: "#010F1C",
              height: "100%",
              width: "100%",
              position: "fixed",
              top: 0,
              left: 0,
              backgroundColor: "rgba(0,0,0,0.91)",
              zIndex: "9999",
            }}
          >
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeLiveFeedPanel}
              onAttemptClose={onAttemptClose}
              style={{
                content: {
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "746px",
                  zIndex: "9999",
                },
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  zIndex: "9999",
                },
              }}
            >
              <div
                style={{
                  width: "100%",
                  maxWidth: "800px",
                  margin: "0 auto",
                  marginTop: "5%",
                  marginLeft: "5%",
                }}
              >
                <div className="tp-return-customer-input">
                  <input
                    type="password"
                    placeholder="Enter farm live feed key"
                    name="LiveFeedKey"
                    id="LiveFeedKey"
                    value={createItem.LiveFeedKey}
                    onChange={onEdit}
                    style={{
                      width: "90%",
                      padding: "8px",
                      marginBottom: "10px",
                    }}
                  />
                </div>

                <div
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <button
                    onClick={onCancel}
                    type="submit"
                    className="tp-return-customer-btn tp-checkout-btn"
                    style={{
                      backgroundColor: "#8d0b0b",
                      // backgroundColor: "#4CAF50",
                      color: "white",
                      border: "none",
                      padding: "10px 20px",
                      borderRadius: "4px",
                      fontSize: "16px",
                      cursor: "pointer",
                      marginRight: "2px",
                      width: "45%",
                    }}
                  >
                    Cancel
                  </button>

                  <button
                    onClick={submitLiveFeedKey}
                    type="submit"
                    className="tp-return-customer-btn tp-checkout-btn"
                    style={{
                      backgroundColor: "#008435",
                      color: "white",
                      border: "none",
                      padding: "10px 20px",
                      borderRadius: "4px",
                      fontSize: "16px",
                      cursor: "pointer",
                      marginBottom: "10px",
                      width: "45%",
                    }}
                  >
                    Submit
                  </button>
                </div>
                {isSubmittingLoader && <SubmittingLoader />}
              </div>
            </Modal>
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
  },
  title: {
    fontSize: "24px",
    marginBottom: "20px",
  },
  videoContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
    gridGap: "20px",
  },
  videoWrapper: {
    border: "1px solid #ccc",
    padding: "10px",
    borderRadius: "5px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
  },
  video: {
    width: "100%",
    height: "200px",
  },
  videoTitle: {
    fontSize: "18px",
    marginTop: "10px",
  },
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveStreaming);
