import "swiper/swiper.min.css";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { setLoginDetails } from "../../Actions/Actions";
import arrayShuffle from "array-shuffle";
import ErrorBoundary from "../Shared/ErrorBoundary/ErrorBoundary";
import { formatDate, serverLink } from "../../Resources/Url";
import ready from "../../Resources/ready-to-contact.jpg";
import HomeContentLoader from "../Shared/Loaders/HomeContentLoader/HomeContentLoader";
import { isMobile } from "react-device-detect";
import FooterContact from "../Shared/FooterContact/FooterContact";

const inputStyle = {
  padding: "8px",
  borderRadius: "4px",
  border: "1px solid #ccc",
  marginRight: "10px",
  width: "200px",
};

const buttonStyle = {
  padding: "8px 12px",
  backgroundColor: "#008435",
  color: "white",
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
};
const About = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [staff_, setStaff_] = useState([]);
  const [modalActive, setModalActive] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);

  const openModal = () => {
    setModalActive(true);
  };

  const closeModal = () => {
    setModalActive(false);
    window.location.reload();
  };

  const [staff] = useState(
    typeof props?.HomePageData.staff !== "undefined"
      ? arrayShuffle(props?.HomePageData.staff)
      : []
  );

  const onViewMore = () => {
    setStaff_(staff);
  };

  const filteredStaff = staff
    .filter((x) => x.IsActive === Number(1))
    .slice(0, 6);

  const ceo = staff.filter(
    (x) => x.Appointment === "ceo" && x.IsActive === Number(1)
  );

  const handleSearch = (query) => {
    const lowerCaseQuery = query.toLowerCase();
    const filtered = staff_.filter((item) =>
      item.Title.toLowerCase().includes(lowerCaseQuery)
    );
    setFilteredResults(filtered);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setIsLoading(false);
      setStaff_(filteredStaff);
    }, 500);
  }, []);

  const onClickModelOpen = () => {
    openModal();
  };

  return (
    <>
      {isLoading ? (
        <HomeContentLoader />
      ) : (
        <ErrorBoundary>
          <section
            className="ready-to-contact section-padding"
            style={{
              background: "url(./assets/images/funfact-bg.jpg)",
            }}
          >
            <div className="container">
              <div className="col-lg-8 offset-lg-2">
                <div className="ready-to-contact-content">
                  <h2>What is NALDA?</h2>
                  <p>
                    NALDA (National Agricultural Land Development Authority), is
                    a body established in 1992 but it went into extinction in
                    2000. However under the administration of the President
                    Muhammadu Buhari, it was revived to carry out its mandate of
                    developing rural communities through agriculture and to make
                    agriculture a business and a source of wealth creation for
                    the country.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="about-inner section-padding">
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-5 wow fadeInUp"
                  data-wow-duration="1.5s"
                  data-wow-delay="300ms"
                >
                  <div className="about-inner-img">
                    <img src={`${ceo[0].Image}`} alt="" />
                  </div>
                </div>

                <div
                  className="col-lg-6 wow fadeInUp"
                  data-wow-duration="1.5s"
                  data-wow-delay="400ms"
                >
                  <div className="about-inner-content">
                    <h2 className="column-title2 column-title">
                      {ceo[0].FullName}
                    </h2>
                    <div className="single-about-content">
                      <h3>About the Secretary/CEO </h3>
                      <p>{ceo[0].Biography}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/*<section*/}
          {/*  class="team-sec section-padding"*/}
          {/*  style={{*/}
          {/*    backgroundColor: "rgba(190,188,188,0.13)",*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <div class="container">*/}
          {/*    <div class="row">*/}
          {/*      <div class="col-lg-3 col-sm-6">*/}
          {/*        <div class="single-team">*/}
          {/*          <div class="team-img">*/}
          {/*            <img src="assets/images/team/team_1.jpg" alt="" />*/}
          {/*            <div class="team-social">*/}
          {/*              <a href="#">*/}
          {/*                <i class="fa fa-facebook"></i>*/}
          {/*              </a>*/}
          {/*              <a href="#">*/}
          {/*                <i class="fa fa-twitter"></i>*/}
          {/*              </a>*/}
          {/*              <a href="#">*/}
          {/*                <i class="fa fa-google-plus"></i>*/}
          {/*              </a>*/}
          {/*              <a href="#">*/}
          {/*                <i class="fa fa-linkedin"></i>*/}
          {/*              </a>*/}
          {/*            </div>*/}
          {/*          </div>*/}
          {/*          <h3>Mr. Jon miller</h3>*/}
          {/*          <p>Gardener</p>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      <div class="col-lg-3 col-sm-6">*/}
          {/*        <div class="single-team">*/}
          {/*          <div class="team-img">*/}
          {/*            <img src="assets/images/team/team_2.jpg" alt="" />*/}
          {/*            <div class="team-social">*/}
          {/*              <a href="#">*/}
          {/*                <i class="fa fa-facebook"></i>*/}
          {/*              </a>*/}
          {/*              <a href="#">*/}
          {/*                <i class="fa fa-twitter"></i>*/}
          {/*              </a>*/}
          {/*              <a href="#">*/}
          {/*                <i class="fa fa-google-plus"></i>*/}
          {/*              </a>*/}
          {/*              <a href="#">*/}
          {/*                <i class="fa fa-linkedin"></i>*/}
          {/*              </a>*/}
          {/*            </div>*/}
          {/*          </div>*/}
          {/*          <h3>Mr. Kibria mou</h3>*/}
          {/*          <p>Gardener</p>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      <div class="col-lg-3 col-sm-6">*/}
          {/*        <div class="single-team">*/}
          {/*          <div class="team-img">*/}
          {/*            <img src="assets/images/team/team_3.jpg" alt="" />*/}
          {/*            <div class="team-social">*/}
          {/*              <a href="#">*/}
          {/*                <i class="fa fa-facebook"></i>*/}
          {/*              </a>*/}
          {/*              <a href="#">*/}
          {/*                <i class="fa fa-twitter"></i>*/}
          {/*              </a>*/}
          {/*              <a href="#">*/}
          {/*                <i class="fa fa-google-plus"></i>*/}
          {/*              </a>*/}
          {/*              <a href="#">*/}
          {/*                <i class="fa fa-linkedin"></i>*/}
          {/*              </a>*/}
          {/*            </div>*/}
          {/*          </div>*/}
          {/*          <h3>Mis. Jaqulin</h3>*/}
          {/*          <p>Gardener</p>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      <div class="col-lg-3 col-sm-6">*/}
          {/*        <div class="single-team">*/}
          {/*          <div class="team-img">*/}
          {/*            <img src="assets/images/team/team_4.jpg" alt="" />*/}
          {/*            <div class="team-social">*/}
          {/*              <a href="#">*/}
          {/*                <i class="fa fa-facebook"></i>*/}
          {/*              </a>*/}
          {/*              <a href="#">*/}
          {/*                <i class="fa fa-twitter"></i>*/}
          {/*              </a>*/}
          {/*              <a href="#">*/}
          {/*                <i class="fa fa-google-plus"></i>*/}
          {/*              </a>*/}
          {/*              <a href="#">*/}
          {/*                <i class="fa fa-linkedin"></i>*/}
          {/*              </a>*/}
          {/*            </div>*/}
          {/*          </div>*/}
          {/*          <h3>Mr. Pongvan</h3>*/}
          {/*          <p>Gardener</p>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</section>*/}

          <FooterContact />
        </ErrorBoundary>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(About);
