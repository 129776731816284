import "swiper/swiper.min.css";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { setLoginDetails } from "../../Actions/Actions";
import arrayShuffle from "array-shuffle";
import ErrorBoundary from "../Shared/ErrorBoundary/ErrorBoundary";
import ready from "../../Resources/ready-to-contact.jpg";
import work_1 from "../../Resources/work_1.jpg";
import HomeContentLoader from "../Shared/Loaders/HomeContentLoader/HomeContentLoader";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { Modal } from "react-st-modal";
import { formatDate, serverLink } from "../../Resources/Url";
import FooterContact from "../Shared/FooterContact/FooterContact";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import YouTube from "react-youtube";
import ProjectSlider from "../Shared/ProjectSlider";

const searchBarStyle = {
  display: "flex",
  alignItems: "center",
  marginBottom: "20px",
};

const inputStyle = {
  padding: "8px",
  borderRadius: "4px",
  border: "1px solid #ccc",
  marginRight: "10px",
  width: "200px",
};

const buttonStyle = {
  padding: "8px 12px",
  backgroundColor: "#008435",
  color: "white",
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
};

const containerStyle = {
  textAlign: "center",
};

const videoContainerStyle = {
  margin: "10px",
  cursor: "pointer",
};

const thumbnailStyle = {
  height: "150px",
  width: "300px",
};

SwiperCore.use([Autoplay, Pagination, Navigation]);
const Gallery = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [galleries, setGalleries] = useState([]);
  const [modalActive, setModalActive] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [filterGalleryInformation, setFilterGalleryInformation] = useState([]);
  const swiperRef = React.useRef(null);
  const [filteredProjectSlider, setFilteredProjectSlider] = useState([]);
  const [filteredVideos, setFilteredVideos] = useState([]);

  const openModal = () => {
    setModalActive(true);
  };

  const closeModal = () => {
    setFilterGalleryInformation([]);
    setModalActive(false);
    window.location.reload();
  };

  const [gallery] = useState(
    typeof props?.HomePageData.gallery !== "undefined"
      ? arrayShuffle(props?.HomePageData.gallery)
      : []
  );

  const [projectSlider] = useState(
    typeof props?.HomePageData.projectSlider !== "undefined"
      ? arrayShuffle(props?.HomePageData.projectSlider)
      : []
  );

  const [videoLink] = useState(
    typeof props?.HomePageData.video !== "undefined"
      ? arrayShuffle(props?.HomePageData.video)
      : []
  );

  const onViewMore = () => {
    setGalleries(gallery);
  };

  const filteredGallery = gallery
    .filter((x) => x.IsActive === Number(1))
    .slice(0, 6);

  const handleSearch = (query) => {
    const lowerCaseQuery = query.toLowerCase();
    const filtered = galleries.filter((item) =>
      item.Title.toLowerCase().includes(lowerCaseQuery)
    );
    setFilteredResults(filtered);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setIsLoading(false);
      setGalleries(filteredGallery);
    }, 500);
  }, []);

  const onClickModelOpen = () => {
    openModal();
  };

  const getGalleryInformation = (galleryId) => {
    const filteredGalleryInformation = gallery.filter(
      (x) => x.GalleryId === Number(galleryId)
    );

    const filterProjectSlider = projectSlider.filter(
      (x) => x.GalleryId === Number(galleryId)
    );

    const filterVideos = videoLink
      .filter((x) => x.GalleryId === Number(galleryId))
      .slice(0, 3);

    setFilterGalleryInformation(filteredGalleryInformation);
    setFilteredProjectSlider(filterProjectSlider);
    setFilteredVideos(filterVideos);
  };

  const cardStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  const playButtonStyle = {
    fontSize: "2rem",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    borderRadius: "50%",
    padding: "10px",
    color: "white",
    cursor: "pointer",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  const extractVideoId = (url) => {
    const match = url.match(
      /(?:\?v=|\/embed\/|\/v\/|\/vi\/|\/user\/\w+\/\w+\/|\/embed\/videoseries\?list=)([^#\&\?]*).*/
    );
    return (match && match[1]) || null;
  };

  return (
    <>
      {isLoading ? (
        <HomeContentLoader />
      ) : (
        <ErrorBoundary>
          <div
            style={{
              overflow: "auto",
              // width: "1140px",
            }}
          >
            <section
              className="banner-inner-sec"
              style={{
                backgroundImage: `url(${ready})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundSize: "cover",
              }}
            >
              <div className="banner-table">
                <div className="banner-table-cell">
                  <div className="container">
                    <div className="banner-inner-content">
                      <h2 className="banner-inner-title">Gallery</h2>
                      <ul className="xs-breadcumb">
                        <li>
                          <a href="index.html"> Home / </a> Gallery
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="recent-work-sec section-padding">
              <div className="container">
                <div className="section-title-item">
                  <h2 className="section-title">
                    <span className="xs-title">Our Gallery</span>
                    Latest art Gallery
                  </h2>
                </div>

                <div className="search-bar" style={searchBarStyle}>
                  <input
                    type="text"
                    placeholder="Search gallery..."
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                      handleSearch(e.target.value);
                    }}
                    style={inputStyle}
                  />
                  <button
                    onClick={() => {
                      setSearchQuery("");
                      setFilteredResults([]);
                    }}
                    style={buttonStyle}
                  >
                    Clear Search
                  </button>
                </div>

                <div className="row" id="mixcontent">
                  {(searchQuery.length > 0 ? filteredResults : galleries).map(
                    (item, index) => (
                      <>
                        <>
                          <div
                            key={index}
                            className="col-lg-4 mix cat3 col-sm-6"
                            style={{
                              display: "inline-block",
                            }}
                            data-bound
                          >
                            <div
                              className="xs-image-popup"
                              data-effect="mfp-zoom-in"
                              onClick={(e) => {
                                onClickModelOpen();
                                getGalleryInformation(item.GalleryId);
                              }}
                            >
                              <div className="single-recent-work">
                                <img src={`${item.FrontImage}`} alt="" />
                                <div className="recet-work-footer">
                                  <i className="icon-Our_service_3"></i>
                                  <h3>{item.Title}</h3>
                                </div>
                              </div>
                            </div>

                            {filterGalleryInformation &&
                              Object.keys(filterGalleryInformation).length >
                                0 && (
                                <>
                                  {/*INFORMATION MODAL START*/}
                                  <div
                                    id="customModal"
                                    style={{
                                      display: modalActive ? "block" : "none",
                                      position: "fixed",
                                      overflowY: "auto",
                                      zIndex: 9999,
                                      left: 0,
                                      top: 0,
                                      width: "100%",
                                      height: "100%",
                                      backgroundColor: "rgba(0,0,0,0.81)",
                                    }}
                                  >
                                    <div
                                      className="modal-content"
                                      style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        zIndex: "9999",
                                        width: isMobile ? "450px" : "1140px",
                                        transform: "translate(-50%, -50%)",
                                        backgroundColor: "#fff",
                                        // padding: "20px",
                                        borderRadius: "5px",
                                        boxShadow:
                                          "0px 2px 10px rgba(0, 0, 0, 0.2)",
                                      }}
                                    >
                                      <div
                                        className="row"
                                        style={{
                                          width: isMobile ? "450px" : "1140px",
                                          height: "100%",
                                          // overflow: "auto",
                                          zIndex: "9999",
                                        }}
                                      >
                                        {filteredProjectSlider &&
                                        Object.keys(filteredProjectSlider)
                                          .length > 0 ? (
                                          <>
                                            <div
                                              key={index}
                                              className="col-lg-5 xs-padding-0"
                                            >
                                              <div className="xs-popup-img">
                                                <Swiper
                                                  ref={swiperRef} // Attach the Swiper instance to the ref
                                                  spaceBetween={30}
                                                  centeredSlides={true}
                                                  autoplay={{
                                                    delay: 3000,
                                                    disableOnInteraction: false,
                                                  }}
                                                  pagination={{
                                                    clickable: true,
                                                  }}
                                                  navigation={false} // Disable the default navigation
                                                  className="mySwiper"
                                                >
                                                  {filteredProjectSlider.map(
                                                    (item, index) => {
                                                      return (
                                                        <SwiperSlide>
                                                          <img
                                                            src={`${item.Slider2}`}
                                                            alt=""
                                                          />
                                                        </SwiperSlide>
                                                      );
                                                    }
                                                  )}
                                                </Swiper>
                                              </div>
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            <div className="col-lg-5 xs-padding-0">
                                              <div className="xs-popup-img">
                                                <img
                                                  src={`${filterGalleryInformation[0].MainImage}`}
                                                  alt=""
                                                />
                                              </div>
                                            </div>
                                          </>
                                        )}

                                        <div className="col-lg-7">
                                          <div className="xs-popup-content">
                                            <h2 className="hidden-title">
                                              Project info
                                            </h2>
                                            <h3
                                              style={{
                                                margin: "20px",
                                              }}
                                            >
                                              {
                                                filterGalleryInformation[0]
                                                  .Title
                                              }
                                            </h3>
                                            <div
                                              className="row"
                                              style={{
                                                margin: "10px",
                                              }}
                                            >
                                              <div className="col-lg-5">
                                                <ul className="xs-popup-left-content">
                                                  <li>
                                                    <i className="icon icon-calendar-full"></i>
                                                    <label>Date</label>
                                                    <span>
                                                      {
                                                        filterGalleryInformation[0]
                                                          .Date
                                                      }
                                                    </span>
                                                  </li>
                                                  <li>
                                                    <i className="icon icon-map-marker2"></i>
                                                    <label>Location</label>
                                                    <span>
                                                      {
                                                        filterGalleryInformation[0]
                                                          .Location
                                                      }
                                                    </span>
                                                  </li>
                                                </ul>
                                              </div>

                                              <div className="col-lg-7">
                                                <div className="xs-popup-right-content">
                                                  <p>
                                                    {
                                                      filterGalleryInformation[0]
                                                        .ParagraphOne
                                                    }
                                                  </p>
                                                  <blockquote>
                                                    “We must Produce What we Eat
                                                    and Eat What we Produce.”
                                                  </blockquote>
                                                  <p>
                                                    {
                                                      filterGalleryInformation[0]
                                                        .ParagraphTwo
                                                    }
                                                  </p>
                                                </div>
                                              </div>

                                              {filteredVideos &&
                                                filteredVideos.length > 0 && (
                                                  <div className="card-deck">
                                                    {filteredVideos.map(
                                                      (video, index) => {
                                                        const videoId =
                                                          extractVideoId(
                                                            video.VideoUrlOne
                                                          );
                                                        const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;

                                                        return (
                                                          <div
                                                            className="card"
                                                            key={index}
                                                            style={cardStyle}
                                                          >
                                                            <div className="card-body">
                                                              <a
                                                                href={
                                                                  video.VideoUrlOne
                                                                }
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                              >
                                                                <img
                                                                  src={
                                                                    thumbnailUrl
                                                                  }
                                                                  alt={
                                                                    video.title
                                                                  }
                                                                  style={{
                                                                    maxWidth:
                                                                      "100%",
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                />
                                                                <div
                                                                  className="play-button"
                                                                  style={
                                                                    playButtonStyle
                                                                  }
                                                                >
                                                                  ▶
                                                                </div>
                                                              </a>
                                                            </div>
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                )}

                                              <div
                                                style={{
                                                  display: "flex",
                                                  gap: "5px",
                                                }}
                                              >
                                                <Link
                                                  to={`/${filterGalleryInformation[0].ProjectUrl}`}
                                                  className="xs-btn"
                                                >
                                                  {filterGalleryInformation[0]
                                                    .ProjectUrl &&
                                                    Object.keys(
                                                      filterGalleryInformation[0]
                                                        .ProjectUrl
                                                    ).length > 0 && (
                                                      <>View Project</>
                                                    )}
                                                </Link>
                                                {isMobile && (
                                                  <a
                                                    onClick={closeModal}
                                                    className="xs-btn"
                                                    style={{
                                                      backgroundColor:
                                                        "#770b0b",
                                                      color: "white",
                                                    }}
                                                  >
                                                    Close
                                                  </a>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      {!isMobile && (
                                        <>
                                          <button
                                            title="Close (Esc)"
                                            type="button"
                                            className="mfp-close"
                                            style={{
                                              color: "red",
                                              zIndex: "9999",
                                            }}
                                            onClick={closeModal}
                                          >
                                            x
                                          </button>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  {/*INFORMATION MODAL END*/}
                                </>
                              )}
                          </div>
                        </>
                      </>
                    )
                  )}
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="load-more-btn">
                      <a
                        className="xs-btn fill"
                        onClick={onViewMore}
                        style={{
                          backgroundColor: "#008435",
                          color: "white",
                        }}
                      >
                        View More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <FooterContact />
        </ErrorBoundary>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
