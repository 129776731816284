import React from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { setLoginDetails } from "../../Actions/Actions";
import { connect } from "react-redux";
import Home from "../Home/Home";
import Footer from "../Shared/Footer/Footer";
import Header from "../Shared/Header/Header";
import Slider from "../Shared/Slider/Slider";
import TopHeader from "../Shared/TopHeader/TopHeader";
import HeaderMiddleSection from "../Shared/HeaderMiddleSection/HeaderMiddleSection";
import SingleProject from "../SingleProject/SingleProject";
import Projects from "../Projects/Projects";
import Gallery from "../Gallery/Gallery";
import About from "../About/About";
import Contact from "../Contact/Contact";
import News from "../News/News";
import SingleNews from "../SingleNews/SingleNews";
import Programmes from "../Programmes/Programmes";
import SingleProgramme from "../SingleProgramme/SingleProgramme";
import LiveStreaming from "../LiveStreaming/LiveStreaming";
import OfficesLocations from "../OfficesLocations/OfficesLocation";
import Resources from "../Resources/Resources";

function Routing() {
  const location = useLocation();
  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment !== "");
  const firstItem = pathSegments[0];

  return (
    <>
      {!firstItem ? (
        <>
          <TopHeader />
          <HeaderMiddleSection />
          <Header />
          <Slider />
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="*" element={<Home />} />
          </Routes>
          <Footer />
        </>
      ) : (
        <>
          {firstItem === "stream" ? (
            <>
              <Routes>
                <Route path="/stream/:slug/:slug" element={<LiveStreaming />} />
              </Routes>
            </>
          ) : (
            <>
              <TopHeader />
              <HeaderMiddleSection />
              <Header />
              <Routes>
                <Route path="/home" element={<Home />} />
                <Route path="/programme/:slug" element={<SingleProgramme />} />
                <Route path="/projects/:slug" element={<SingleProject />} />
                <Route path="/news/:slug" element={<SingleNews />} />
                <Route path="/programme" element={<Programmes />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />

                <Route path="/news" element={<News />} />
                <Route path="/offices" element={<OfficesLocations />} />
                <Route path="/resources" element={<Resources />} />

                <Route path="*" element={<Home />} />
              </Routes>
              <Footer />
            </>
          )}
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Routing);
