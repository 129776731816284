import React from "react";
import { useState, CSSProperties } from "react";
import { ColorRing, Oval } from "react-loader-spinner";

function HomeContentLoader() {
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#370d59");

  const spinnerStyle = {
    fontSize: "40px",
    marginBottom: "10px",
  };

  const loadingMessageStyle = {
    textAlign: "center",
  };

  const spinnerContainerStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgb(0,75,30)",
    // backgroundColor: "rgb(255,255,255)",
    color: "#fff",
    fontSize: "16px",
    zIndex: "9999",
  };

  return (
    <div style={spinnerContainerStyle}>
      <div className="tp-preloader-logo">
        <div className="tp-preloader-circle">
          <Oval
            height={90}
            width={90}
            color="#fff"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="gray"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
          <br />
          <p
            className="tp-preloader-subtitle"
            style={{ alignContent: "center" }}
          ></p>
        </div>
      </div>
    </div>
  );
}

export default HomeContentLoader;
