import "swiper/swiper.min.css";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { setLoginDetails } from "../../../Actions/Actions";
import arrayShuffle from "array-shuffle";
import ErrorBoundary from "../../Shared/ErrorBoundary/ErrorBoundary";
import ready from "../../../Resources/ready-to-contact.jpg";
import { Link } from "react-router-dom";

const FooterContact = (props) => {
  const [projects] = useState(
    typeof props?.HomePageData.projects !== "undefined"
      ? arrayShuffle(props?.HomePageData.projects)
      : []
  );

  return (
    <>
      <ErrorBoundary>
        <section
          className="ready-to-contact section-padding"
          style={{
            backgroundImage: `url(${ready})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover",
          }}
        >
          <div className="container">
            <div className="col-lg-8 offset-lg-2">
              <div className="ready-to-contact-content">
                <h2>Get in Touch with Us</h2>
                <p>
                  We're committed to providing excellent service and prompt
                  responses to all inquiries. Your input is important to us and
                  helps us improve our offerings to better meet your needs.
                </p>
                <Link to="/contact" className="xs-btn fill">
                  CONTACT US
                </Link>
              </div>
            </div>
          </div>
        </section>
      </ErrorBoundary>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FooterContact);
