import "swiper/swiper.min.css";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setLoginDetails } from "../../Actions/Actions";
import arrayShuffle from "array-shuffle";
import ErrorBoundary from "../Shared/ErrorBoundary/ErrorBoundary";
import ready from "../../Resources/ready-to-contact.jpg";
import HomeContentLoader from "../Shared/Loaders/HomeContentLoader/HomeContentLoader";

const tableStyle = {
  width: "100%",
  borderCollapse: "collapse",
  marginTop: "20px",
};

const thStyle = {
  background: "#008435",
  color: "white",
  padding: "10px",
  textAlign: "left",
};

const tdStyle = {
  padding: "10px",
  borderBottom: "1px solid #ccc",
};

const linkStyle = {
  textDecoration: "none",
  color: "#007bff",
  fontWeight: "bold",
};
const OfficesLocations = (props) => {
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Note: months are zero-indexed
  const currentDay = currentDate.getDate();

  const [addresses] = useState(
    typeof props?.HomePageData.address !== "undefined"
      ? arrayShuffle(props?.HomePageData.address)
      : []
  );

  const [states] = useState(
    typeof props?.HomePageData.states !== "undefined"
      ? arrayShuffle(props?.HomePageData.states)
      : []
  );

  const [lga] = useState(
    typeof props?.HomePageData.lga !== "undefined"
      ? arrayShuffle(props?.HomePageData.lga)
      : []
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    if (addresses && addresses.length > 0) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <HomeContentLoader />
      ) : (
        <ErrorBoundary>
          <section
            className="banner-inner-sec"
            style={{
              backgroundImage: `url(${ready})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "cover",
            }}
          >
            <div className="banner-table">
              <div className="banner-table-cell">
                <div className="container">
                  <div className="banner-inner-content">
                    <h2 className="banner-inner-title">Offices Locations</h2>
                    <ul className="xs-breadcumb">
                      <li>
                        <a href="index.html"> Home / </a> Offices Locations
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="xs-get-in-touch">
            <div className="container">
              <h3>
                <span className="light-text">NALDA</span> Offices Locations
              </h3>
              <p>
                We're committed to providing excellent service and prompt
                responses to all inquiries. Your input is important to us and
                helps us improve our offerings to better meet your needs.
              </p>
            </div>
            <div className="container">
              <div>
                <table style={tableStyle}>
                  <thead>
                    <tr>
                      <th style={thStyle}>Address</th>
                      <th style={thStyle}>State</th>
                      <th style={thStyle}>LGA</th>
                      <th style={thStyle}>Location on Map</th>
                    </tr>
                  </thead>
                  <tbody>
                    {addresses.map((address) => (
                      <tr key={address.AddressId}>
                        <td style={tdStyle}>{address.Address}</td>

                        <td style={tdStyle}>
                          {
                            states
                              .filter(
                                (i) => i.StateId === Number(address.StateId)
                              )
                              .map((x) => <>{x.Title}</>)[0]
                          }{" "}
                        </td>
                        <td style={tdStyle}>
                          {
                            lga
                              .filter((i) => i.LGAId === Number(address.LGAId))
                              .map((x) => <>{x.Title}</>)[0]
                          }{" "}
                        </td>
                        <td style={tdStyle}>
                          <a
                            href={address.GoogleMap}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={linkStyle}
                          >
                            View on Google Maps
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </section>
          <br />
        </ErrorBoundary>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OfficesLocations);
