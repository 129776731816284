import React, { useState } from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";

const SocialShare = ({ url, title }) => {
  const [buttonStyle, setButtonStyle] = useState({
    display: "flex",
    alignItems: "center",
    backgroundColor: "#008435",
    color: "#fff",
    padding: "10px 15px",
    borderRadius: "5px",
    cursor: "pointer",
    textDecoration: "none",
    fontWeight: "bold",
    transition: "background-color 0.2s ease-in-out",
  });

  const buttonHoverStyle = {
    backgroundColor: "#055927",
  };

  return (
    <div style={{ textAlign: "left" }}>
      <h3>Share this post:</h3>
      <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
        <FacebookShareButton
          url={url}
          quote={title}
          style={buttonStyle}
          onMouseOver={() =>
            setButtonStyle({ ...buttonStyle, ...buttonHoverStyle })
          }
          onMouseOut={() => setButtonStyle({ ...buttonStyle })}
        >
          Facebook
        </FacebookShareButton>
        <TwitterShareButton
          url={url}
          title={title}
          style={buttonStyle}
          onMouseOver={() =>
            setButtonStyle({ ...buttonStyle, ...buttonHoverStyle })
          }
          onMouseOut={() => setButtonStyle({ ...buttonStyle })}
        >
          Twitter
        </TwitterShareButton>
        <LinkedinShareButton
          url={url}
          title={title}
          style={buttonStyle}
          onMouseOver={() =>
            setButtonStyle({ ...buttonStyle, ...buttonHoverStyle })
          }
          onMouseOut={() => setButtonStyle({ ...buttonStyle })}
        >
          LinkedIn
        </LinkedinShareButton>
      </div>
    </div>
  );
};

export default SocialShare;
