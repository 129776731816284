import "swiper/swiper.min.css";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { setLoginDetails } from "../../Actions/Actions";
import arrayShuffle from "array-shuffle";
import ErrorBoundary from "../Shared/ErrorBoundary/ErrorBoundary";
import { serverLink } from "../../Resources/Url";
import ready from "../../Resources/ready-to-contact.jpg";
import HomeContentLoader from "../Shared/Loaders/HomeContentLoader/HomeContentLoader";
import FooterContact from "../Shared/FooterContact/FooterContact";
import { showAlert } from "../Shared/Sweetalert/sweetalert";
import axios from "axios";
import SubmittingLoader from "../Shared/SubmittingLoader/SubmittingLoader";
import { Link } from "react-router-dom";

const Contact = (props) => {
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Note: months are zero-indexed
  const currentDay = currentDate.getDate();

  const [projects] = useState(
    typeof props?.HomePageData.projects !== "undefined"
      ? arrayShuffle(props?.HomePageData.projects)
      : []
  );

  const [createItem, setCreateItem] = useState({
    MessageId: "",
    FullName: "",
    Subject: "",
    EmailAddress: "",
    PhoneNumber: "",
    Message: "",
    Date: "",
    IsReply: "",
  });

  const resetItem = () => {
    setCreateItem({
      ...createItem,
      MessageId: "",
      FullName: "",
      Subject: "",
      EmailAddress: "",
      PhoneNumber: "",
      Message: "",
      Date: "",
      IsReply: "",
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (projects && projects.length > 0) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, []);

  const onEdit = async (e) => {
    const id = e.target.id;
    const value = e.target.value;

    setCreateItem({
      ...createItem,
      [id]: value,
    });
  };

  const onSubmit = async (e) => {
    setIsSubmittingForm(true);
    e.preventDefault();
    for (let key in createItem) {
      if (
        createItem.hasOwnProperty(key) &&
        key !== "MessageId" &&
        key !== "Date" &&
        key !== "IsReply"
      ) {
        if (createItem[key] === "") {
          await showAlert("EMPTY FIELD", `Please enter ${key}`, "error");
          setIsSubmittingForm(false);
          return false;
        }
      }
    }

    const sendData = {
      MessageId: createItem.MessageId,
      FullName: createItem.FullName,
      Subject: createItem.Subject,
      EmailAddress: createItem.EmailAddress,
      PhoneNumber: createItem.PhoneNumber,
      Message: createItem.Message,
      IsReply: Number(0),
      Date: `${currentMonth}/${currentDay}/${currentYear}`,
    };

    if (sendData) {
      axios
        .post(`${serverLink}message/send`, sendData)
        .then(async (res) => {
          if (res.data.message === "success") {
            resetItem();
            await showAlert(
              "Success",
              `Message submitted successfully`,
              "success"
            );
            setIsSubmittingForm(false);
            resetItem();
          }
          if (res.data.message === "exist") {
            await showAlert(
              "Error",
              `You already have a ticket; our team will reach out soon.`,
              "error"
            );
            setIsSubmittingForm(false);
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
  };

  return (
    <>
      {isLoading ? (
        <HomeContentLoader />
      ) : (
        <ErrorBoundary>
          <section
            className="banner-inner-sec"
            style={{
              backgroundImage: `url(${ready})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "cover",
            }}
          >
            <div className="banner-table">
              <div className="banner-table-cell">
                <div className="container">
                  <div className="banner-inner-content">
                    <h2 className="banner-inner-title">Contact Us</h2>
                    <ul className="xs-breadcumb">
                      <li>
                        <a href="index.html"> Home / </a> Contact Us
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="xs-get-in-touch">
            <div class="container">
              <div class="row">
                <div class="col-lg-8 mx-auto">
                  <div class="get-in-touch-cont text-center">
                    <h3>
                      <span class="light-text">Get</span> in touch
                    </h3>
                    <p>
                      We're committed to providing excellent service and prompt
                      responses to all inquiries. Your input is important to us
                      and helps us improve our offerings to better meet your
                      needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="xs-contact-sec">
            <div class="container">
              <div class="row">
                <div class="col-lg-7">
                  <div class="xs-form-group">
                    <form id="xs-contact-form" class="xs-form">
                      <div class="row">
                        <div class="col-lg-6">
                          <input
                            class="form-control"
                            name="FullName"
                            id="FullName"
                            type="text"
                            placeholder="Full Name"
                            value={createItem.FullName}
                            onChange={onEdit}
                          />
                        </div>
                        <div class="col-lg-6">
                          <input
                            class="form-control"
                            name="EmailAddress"
                            id="EmailAddress"
                            type="email"
                            placeholder="Email Address"
                            value={createItem.EmailAddress}
                            onChange={onEdit}
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6">
                          <input
                            class="form-control"
                            name="PhoneNumber"
                            id="PhoneNumber"
                            type="text"
                            placeholder="Phone Number"
                            value={createItem.PhoneNumber}
                            onChange={onEdit}
                          />
                        </div>
                        <div class="col-lg-6">
                          <input
                            class="form-control"
                            name="Subject"
                            id="Subject"
                            type="text"
                            placeholder="Subject"
                            value={createItem.Subject}
                            onChange={onEdit}
                          />
                        </div>
                      </div>

                      <textarea
                        id="Message"
                        type="text"
                        style={{
                          height: "120px",
                        }}
                        placeholder="Message"
                        value={createItem.Message}
                        onChange={onEdit}
                        class="form-control message-box"
                        cols="30"
                        rows="10"
                      ></textarea>

                      <div class="xs-btn-wraper">
                        <input
                          onClick={onSubmit}
                          class="xs-btn"
                          id="xs_contact_submit"
                          value="SEND MESSAGE"
                        />
                      </div>
                      {isSubmittingForm && <SubmittingLoader />}
                    </form>
                  </div>
                </div>
                <div class="col-lg-5">
                  <div class="contact-map">
                    <div
                      style={{
                        width: "100%",
                      }}
                    >
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.016381000246!2d7.5185333000000005!3d9.0622697!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e0bd3fe010485%3A0x7cc76e426b10689f!2sAso%20Rock%20Presidential%20Villa!5e0!3m2!1sen!2sng!4v1695235932978!5m2!1sen!2sng"></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="xs-contact-infomation xs-contact-info-1">
            <div class="container">
              <div class="row">
                <div class="col-lg-4 col-md-6">
                  <div class="contact-info-group">
                    <i class="icon-address"></i>
                    <h4>Visit our office</h4>
                    <span>No 2a Usman Danfodio Crescent</span>
                    <span class="text-color">Aso Villa, FCTA, Nigeria</span>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div class="contact-info-group">
                    <i class="icon-mail"></i>
                    <h4>Mail us</h4>
                    <a href="mailto:info@nalda.gov.ng">info@nalda.gov.ng</a>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div class="contact-info-group">
                    <i class="icon-call"></i>
                    <h4>Call us</h4>
                    <span>0703 326 4358</span>
                    <span class="text-color">
                      (Monday- Friday) at 8.am to 4pm
                    </span>
                  </div>
                </div>
                <br />
                <div className="col-lg-12 col-md-6 m-3">
                  <div className="contact-info-group">
                    <i className="icon-c"></i>
                    <h4>State Presence</h4>

                    <Link to="/offices">
                      <span className="text-color">View Locations?</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </ErrorBoundary>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
