import "swiper/swiper.min.css";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { formatStringToDate, serverLink } from "../../Resources/Url";
import { setLoginDetails } from "../../Actions/Actions";
import axios from "axios";
import {
  FacebookShareButton,
  TwitterShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
} from "react-share";
import ErrorBoundary from "../Shared/ErrorBoundary/ErrorBoundary";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HomeContentLoader from "../Shared/Loaders/HomeContentLoader/HomeContentLoader";
import arrayShuffle from "array-shuffle";
import ready from "../../Resources/ready-to-contact.jpg";
import SocialShare from "../ShareLink/ShareLink";

const SingleNews = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [news, setNews] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);

  const lastCharacter = Number(window.location.pathname.match(/(\d+)$/)[0]);

  const sendData = {
    NewsId: Number(lastCharacter),
  };
  const [news__] = useState(
    typeof props?.HomePageData.news !== "undefined"
      ? arrayShuffle(props?.HomePageData.news)
      : []
  );

  const [staff] = useState(
    typeof props?.HomePageData.staff !== "undefined"
      ? arrayShuffle(props?.HomePageData.staff)
      : []
  );

  const reloadPage = () => {
    window.location.reload();
  };

  const news_ = news__.filter((x) => x.IsActive === Number(1)).slice(0, 6);

  const filteredNews = news_
    .filter((x) => x.IsActive === Number(1))
    .slice(0, 6);

  const handleSearch = (query) => {
    const lowerCaseQuery = query.toLowerCase();
    const filtered = news_.filter((item) =>
      item.Title.toLowerCase().includes(lowerCaseQuery)
    );
    setFilteredResults(filtered);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchProgrammes = async () => {
      try {
        const response = await axios.post(`${serverLink}shared/news`, sendData);

        setNews(response.data);
      } catch (error) {
        navigate("/");
        console.error("Error fetching product:", error);
      }
    };

    fetchProgrammes().then((r) => {});
  }, []);

  if (Object.keys(news).length === 0) {
    return <HomeContentLoader />;
  }
  const url = `https://nalda.gov.ng/news${window.location.pathname}`;
  const title = news.Title;


  function extractVideoId(youtubeLink) {
    // Use a regular expression to match the video ID
    const regex = /[?&]v=([^&]+)/;
    const match = youtubeLink.match(regex);

    if (match && match[1]) {
      return match[1];
    } else {
      // Handle cases where the video ID is not found
      return null;
    }
  }

// Example usage:
  const youtubeLink = news.Video;

  const videoID = youtubeLink ? extractVideoId(youtubeLink) : null;


  console.log("videoID", videoID)
  console.log("youtubeLink", youtubeLink)

  return (
    <ErrorBoundary>
      <section
        className="banner-inner-sec"
        style={{
          backgroundImage: `url(${ready})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >
        <div className="banner-table">
          <div className="banner-table-cell">
            <div className="container">
              <div className="banner-inner-content">
                <h2 className="banner-inner-title"> {news.Title}</h2>
                <ul className="xs-breadcumb">
                  <li>
                    <a href="index.html"> Home / </a> News / {news.Title}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xs-blog-single-sec section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="blog-content-item single-blog-details">
                <div className="single-blog-item">
                  <div className="blog-post-img">
                    <img src={`${news.NewsImage}`} alt="" />
                  </div>

                  <div className="blog-meta">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="icon icon-calendar-full"></i>
                          {formatStringToDate(news.CreatedDate)}
                        </a>
                      </li>
                    </ul>
                  </div>
                  <h3 className="xs-blog-title">{news.Title}</h3>
                  <p>{news.ParagraphOne}</p>
                  <blockquote>
                    We must Produce What we Eat and Eat What we Produce.
                  </blockquote>
                  <p>{news.ParagraphTwo}</p>
                  <p>{news.ParagraphThree}</p>
                  {youtubeLink &&
                      youtubeLink.length > 0 && (
                          <div>
                            <iframe
                                width="560"
                                height="315"
                                src={`https://www.youtube.com/embed/${videoID}`}
                                frameBorder="0"
                                allowFullScreen
                            ></iframe>
                          </div>
                      )}

                  <div>
                    <SocialShare url={url} title={title} />
                  </div>

                </div>
              </div>
            </div>

            {/*SEARCH NEWS*/}
            <div className="col-lg-4">
              <div className="sidebar-widgets">
                <div className="widget widget-search">
                  <form action="#" className="xs-serach">
                    <div className="input-group">
                      <input
                        type="search"
                        name="search"
                        placeholder="Search news"
                        autoComplete="off"
                        value={searchQuery}
                        onChange={(e) => {
                          setSearchQuery(e.target.value);
                          handleSearch(e.target.value);
                        }}
                      />
                      <button
                        className="search-btn"
                        onClick={() => {
                          setSearchQuery("");
                          setFilteredResults([]);
                        }}
                      >
                        <i className="icon icon-cancel"></i>
                      </button>
                    </div>
                  </form>
                </div>

                <div className="widget">
                  <h4 className="widget-title">
                    <span className="light-text">Latest</span> News
                  </h4>

                  <div className="widget-posts">
                    <>
                    {(searchQuery.length > 0
                      ? filteredResults
                      : filteredNews
                    ).map((item, index) => (
                      <div className="widget-post media" onClick={reloadPage}>
                        <Link to={`/news/${item.Slug}`}>
                          <img
                            src={`${item.LatestImage}`}
                            className="d-flex"
                            alt="popular post image"
                            draggable="false"
                          />
                        </Link>

                        <div className="media-body">
                          <Link to={`/news/${item.Slug}`}>
                            <span className="post-meta-date">
                              <a href="#">
                                {" "}
                                Posted by
                                {
                                  staff
                                    .filter(
                                      (i) => i.StaffID === Number(item.StaffId)
                                    )
                                    .map((x) => <> {x.FullName}</>)[0]
                                }
                              </a>
                            </span>
                            <h5 className="entry-title">
                              <a href="blog-single.html">{item.Title} </a>
                            </h5>
                          </Link>
                        </div>

                      </div>
                    ))}

                      </>
                  </div>

                </div>
              </div>

            </div>
{/*ddd*/}
          </div>
        </div>
      </section>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleNews);
