import "swiper/swiper.min.css";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setLoginDetails } from "../../Actions/Actions";
import arrayShuffle from "array-shuffle";
import ErrorBoundary from "../Shared/ErrorBoundary/ErrorBoundary";
import ready from "../../Resources/ready-to-contact.jpg";
import HomeContentLoader from "../Shared/Loaders/HomeContentLoader/HomeContentLoader";

const Resources = (props) => {
  const [isLoading, setIsLoading] = useState(true);

  const [files] = useState(
    typeof props?.HomePageData.resources !== "undefined"
      ? arrayShuffle(props?.HomePageData.resources)
      : []
  );

  const tableStyle = {
    borderCollapse: "collapse",
    width: "80%",
    marginBottom: "20px",
  };

  const cellStyle = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
  };

  const thStyle = {
    background: "#008435",
    color: "white",
    padding: "10px",
    textAlign: "left",
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (files && files.length > 0) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <HomeContentLoader />
      ) : (
        <ErrorBoundary>
          <section
            className="banner-inner-sec"
            style={{
              backgroundImage: `url(${ready})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "cover",
            }}
          >
            <div className="banner-table">
              <div className="banner-table-cell">
                <div className="container">
                  <div className="banner-inner-content">
                    <h2 className="banner-inner-title">
                      Downloadable Resource
                    </h2>
                    <ul className="xs-breadcumb">
                      <li>
                        <a href="index.html"> Home / </a> Resources
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="xs-get-in-touch">
            <div className="container">
              <h3>
                <span className="light-text">NALDA</span> Downloadable Resources
              </h3>
              <p></p>
            </div>
            <div className="container">
              <table style={tableStyle}>
                <thead style={{ ...cellStyle, ...thStyle }}>
                  <tr>
                    <th style={cellStyle}>File Name</th>
                    <th style={cellStyle}>View</th>
                    <th style={cellStyle}>Download</th>{" "}
                    {/* Add download column */}
                  </tr>
                </thead>
                <tbody>
                  {files.map((file) => (
                    <tr key={file.ResourceId}>
                      <td style={cellStyle}>{file.Title}</td>
                      <td style={cellStyle}>
                        <a href={file.File} target="_blank">
                          View File
                        </a>
                      </td>
                      <td style={cellStyle}>
                        <a
                          href={file.File}
                          download={file.Title}
                          target="_blank"
                        >
                          Download
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>
          <br />
        </ErrorBoundary>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Resources);
