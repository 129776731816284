import React from "react";
import { connect } from "react-redux";
import {setHomePageContents} from "../../../Actions/Actions";

const Preloader = (props) => {
    return (
        <>
            <div id="preloader">
                <div className="preloader-wrapper">
                    <div className="la-ball-clip-rotate-pulse la-2x">
                        <div></div>
                        <div></div>
                    </div>
                </div>
                <a href="" className="cancel-preloader">Cancel Preloader</a>
            </div>


        </>
    );
};

const mapStateToProps = (state) => {
    return {
        HomePageData: state.HomePageContents,
        UserProfile: state.LoginDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnHomePageData: (p) => {
            dispatch(setHomePageContents(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Preloader);
