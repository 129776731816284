import "swiper/swiper.min.css";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setLoginDetails } from "../../Actions/Actions";
import arrayShuffle from "array-shuffle";
import ErrorBoundary from "../Shared/ErrorBoundary/ErrorBoundary";
import { serverLink } from "../../Resources/Url";
import ready from "../../Resources/ready-to-contact.jpg";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import HomeContentLoader from "../Shared/Loaders/HomeContentLoader/HomeContentLoader";
import { isMobile } from "react-device-detect";
import { Swiper, SwiperSlide } from "swiper/react";

const searchBarStyle = {
  display: "flex",
  alignItems: "center",
  marginBottom: "20px",
};

const inputStyle = {
  padding: "8px",
  borderRadius: "4px",
  border: "1px solid #ccc",
  marginRight: "10px",
  width: "200px",
};

const buttonStyle = {
  padding: "8px 12px",
  backgroundColor: "#008435",
  color: "white",
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
};

const SingleProject = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [project, setProject] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [galleries, setGalleries] = useState([]);
  const [modalActive, setModalActive] = useState(false);
  const [filterGalleryInformation, setFilterGalleryInformation] = useState([]);
  const swiperRef = React.useRef(null);
  const [filteredProjectSlider, setFilteredProjectSlider] = useState([]);
  const [filteredVideos, setFilteredVideos] = useState([]);

  const [states] = useState(
    typeof props?.HomePageData.states !== "undefined"
      ? props?.HomePageData.states
      : []
  );

  const [lga] = useState(
    typeof props?.HomePageData.lga !== "undefined"
      ? props?.HomePageData.lga
      : []
  );

  const [projectSlider] = useState(
    typeof props?.HomePageData.projectSlider !== "undefined"
      ? arrayShuffle(props?.HomePageData.projectSlider)
      : []
  );

  const openModal = () => {
    setModalActive(true);
  };

  const closeModal = () => {
    setFilterGalleryInformation([]);
    setModalActive(false);
    window.location.reload();
  };
  const onClickModelOpen = () => {
    openModal();
  };

  // const lastCharacter = window.location.pathname.slice(-1);

  const lastCharacter = Number(window.location.pathname.match(/(\d+)$/)[0]);

  const sendData = {
    ProjectId: Number(lastCharacter),
  };

  const [projects] = useState(
    typeof props?.HomePageData.projects !== "undefined"
      ? arrayShuffle(props?.HomePageData.projects)
      : []
  );

  const [gallery_] = useState(
    typeof props?.HomePageData.gallery !== "undefined"
      ? arrayShuffle(props?.HomePageData.gallery)
      : []
  );

  const gallery = gallery_.filter((x) => x.ProjectId === Number(lastCharacter));
  const filteredProject = projects.filter(
    (x) => x.ProjectId === Number(lastCharacter)
  );

  const [videoLink] = useState(
    typeof props?.HomePageData.video !== "undefined"
      ? arrayShuffle(props?.HomePageData.video)
      : []
  );

  const coverage_ = states.filter(
    (x) => x.StateId === Number(filteredProject[0].StateId)
  );

  const getGalleryInformation = (galleryId) => {
    const filteredGalleryInformation = gallery.filter(
      (x) => x.GalleryId === Number(galleryId)
    );

    const filterProjectSlider = projectSlider.filter(
      (x) => x.GalleryId === Number(galleryId)
    );

    const filterVideos = videoLink
      .filter((x) => x.GalleryId === Number(galleryId))
      .slice(0, 3);

    setFilterGalleryInformation(filteredGalleryInformation);
    setFilteredProjectSlider(filterProjectSlider);
    setFilteredVideos(filterVideos);
  };

  const onViewMore = () => {
    setGalleries(gallery);
  };

  const filteredGallery = gallery
    .filter((x) => x.IsActive === Number(1))
    .slice(0, 6);

  const handleSearch = (query) => {
    const lowerCaseQuery = query.toLowerCase();
    const filtered = galleries.filter((item) =>
      item.Title.toLowerCase().includes(lowerCaseQuery)
    );
    setFilteredResults(filtered);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setIsLoading(false);
      setGalleries(filteredGallery);
    }, 500);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchProject = async () => {
      try {
        const response = await axios.post(
          `${serverLink}shared/projects`,
          sendData
        );

        setProject(response.data);
      } catch (error) {
        navigate("/");
        console.error("Error fetching product:", error);
      }
    };

    fetchProject().then((r) => {});
  }, []);

  if (Object.keys(project).length === 0) {
    return <HomeContentLoader />;
  }

  const cardStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  const playButtonStyle = {
    fontSize: "2rem",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    borderRadius: "50%",
    padding: "10px",
    color: "white",
    cursor: "pointer",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  const extractVideoId = (url) => {
    const match = url.match(
      /(?:\?v=|\/embed\/|\/v\/|\/vi\/|\/user\/\w+\/\w+\/|\/embed\/videoseries\?list=)([^#\&\?]*).*/
    );
    return (match && match[1]) || null;
  };

  return (
    <ErrorBoundary>
      <section
        className="banner-inner-sec"
        style={{
          backgroundImage: `url(${ready})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >
        <div className="banner-table">
          <div className="banner-table-cell">
            <div className="container">
              <div className="banner-inner-content">
                <h2 className="banner-inner-title">{project.Title}</h2>
                <ul className="xs-breadcumb">
                  <li>
                    <a href="index.html"> Home / </a> Project / {project.Title}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="service-inner-sec single-service-sec section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <div className="service-sidebar">
                {project.ChannelId &&
                  Object.keys(project.ChannelId).length > 0 && (
                    <div className="widgets">
                      <ul className="services-link-item">
                        {coverage_[0].Title &&
                          coverage_[0].Title.length > 0 && (
                            <>
                              <li className="active">
                                <a href="#">Location Coverage</a>
                              </li>

                              <li>
                                <Link
                                  to={`/stream/${project.ChannelId}/${project.ProjectId}`}
                                >
                                  {coverage_[0].Title}
                                </Link>
                              </li>
                            </>
                          )}
                      </ul>
                    </div>
                  )}

                <div className="widgets">
                  <h3 className="widget-title">
                    <span>Our</span> brochures
                  </h3>
                  <ul className="brochures-list">
                    <li>
                      <a
                        href="https://drive.google.com/file/d/1_5k1NTZfFyldK0n-b9TtG8DcEy2QNU8J/view?usp=sharing"
                        target="_blank"
                      >
                        <i className="fa fa-file-pdf-o"></i>Download .pdf
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://drive.google.com/file/d/1_5k1NTZfFyldK0n-b9TtG8DcEy2QNU8J/view?usp=sharing"
                        target="_blank"
                      >
                        <i className="fa fa-file-word-o"></i>Download .doc
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="widgets testmonial-widget owl-carousel">
                  <div className="single-testmonial-widget">
                    <i className="fa fa-thumbs-o-up testmonial-icon"></i>
                    <p>
                      "They are awesome and friendlygardening team. We love
                      there there there work and stay”
                    </p>
                    <div className="author-rating">
                      <i className="icon icon-star"></i>
                      <i className="icon icon-star"></i>
                      <i className="icon icon-star"></i>
                      <i className="icon icon-star"></i>
                      <i className="icon icon-star"></i>
                      <label>- Kim Jon</label>
                    </div>
                  </div>
                  <div className="single-testmonial-widget">
                    <i className="fa fa-thumbs-o-up testmonial-icon"></i>
                    <p>
                      "They are awesome and friendlygardening team. We love
                      there there there work and stay”
                    </p>
                    <div className="author-rating">
                      <i className="icon icon-star"></i>
                      <i className="icon icon-star"></i>
                      <i className="icon icon-star"></i>
                      <i className="icon icon-star"></i>
                      <i className="icon icon-star"></i>
                      <label>- Kim Jon</label>
                    </div>
                  </div>
                  <div className="single-testmonial-widget">
                    <i className="fa fa-thumbs-o-up testmonial-icon"></i>
                    <p>
                      "They are awesome and friendlygardening team. We love
                      there there there work and stay”
                    </p>
                    <div className="author-rating">
                      <i className="icon icon-star"></i>
                      <i className="icon icon-star"></i>
                      <i className="icon icon-star"></i>
                      <i className="icon icon-star"></i>
                      <i className="icon icon-star"></i>
                      <label>- Kim Jon</label>
                    </div>
                  </div>
                </div>

                <div className="widgets call-to-action">
                  <h3>Need help?</h3>
                  <p>
                    Got questions or need help? We're here for you! Our support
                    team is ready to assist with anything you need. Don't
                    hesitate to reach out.
                  </p>
                  <Link to="/contact" className="xs-btn sm-btn">
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-9 col-md-8">
              <div className="main-single-service-content">
                <div className="single-service-post-content">
                  <h2 className="column-title column-title2">
                    {project.Title}
                  </h2>
                  <img src={`${project.MainImage}`} alt="" />
                  <p>{project.Description}</p>
                  <p>{project.LongDescription}</p>
                </div>
                {galleries && galleries.length > 0 && (
                  <>
                    <section className="recent-work-sec">
                      <div className="container">
                        <h2 className="column-title column-title2">
                          {project.Title} Gallery
                        </h2>

                        <div className="search-bar" style={searchBarStyle}>
                          <input
                            type="text"
                            placeholder="Search gallery..."
                            value={searchQuery}
                            onChange={(e) => {
                              setSearchQuery(e.target.value);
                              handleSearch(e.target.value);
                            }}
                            style={inputStyle}
                          />
                          <button
                            onClick={() => {
                              setSearchQuery("");
                              setFilteredResults([]);
                            }}
                            style={buttonStyle}
                          >
                            Clear Search
                          </button>
                        </div>

                        <div className="row" id="mixcontent">
                          {(searchQuery.length > 0
                            ? filteredResults
                            : galleries
                          ).map((item, index) => (
                            <>
                              <>
                                <div
                                  key={index}
                                  className="col-lg-4 mix cat3 col-sm-6"
                                  style={{
                                    display: "inline-block",
                                  }}
                                  data-bound
                                >
                                  <div
                                    className="xs-image-popup"
                                    data-effect="mfp-zoom-in"
                                    onClick={(e) => {
                                      onClickModelOpen();
                                      getGalleryInformation(item.GalleryId);
                                    }}
                                  >
                                    <div className="single-recent-work">
                                      <img src={`${item.FrontImage}`} alt="" />
                                      <div className="recet-work-footer">
                                        <i className="icon-Our_service_3"></i>
                                        <h3>{item.Title}</h3>
                                      </div>
                                    </div>
                                  </div>

                                  {filterGalleryInformation &&
                                    Object.keys(filterGalleryInformation)
                                      .length > 0 && (
                                      <>
                                        {/*INFORMATION MODAL START*/}
                                        <div
                                          id="customModal"
                                          style={{
                                            display: modalActive
                                              ? "block"
                                              : "none",
                                            position: "fixed",
                                            overflowY: "auto",
                                            zIndex: 9999,
                                            left: 0,
                                            top: 0,
                                            width: "100%",
                                            height: "100%",
                                            backgroundColor: "rgba(0,0,0,0.81)",
                                          }}
                                        >
                                          <div
                                            className="modal-content"
                                            style={{
                                              position: "absolute",
                                              top: "50%",
                                              left: "50%",
                                              zIndex: "9999",
                                              width: isMobile
                                                ? "450px"
                                                : "1140px",
                                              transform:
                                                "translate(-50%, -50%)",
                                              backgroundColor: "#fff",
                                              // padding: "20px",
                                              borderRadius: "5px",
                                              boxShadow:
                                                "0px 2px 10px rgba(0, 0, 0, 0.2)",
                                            }}
                                          >
                                            <div
                                              className="row"
                                              style={{
                                                width: isMobile
                                                  ? "450px"
                                                  : "1140px",
                                                height: "100%",
                                                // overflow: "auto",
                                                zIndex: "9999",
                                              }}
                                            >
                                              {filteredProjectSlider &&
                                              Object.keys(filteredProjectSlider)
                                                .length > 0 ? (
                                                <>
                                                  <div
                                                    key={index}
                                                    className="col-lg-5 xs-padding-0"
                                                  >
                                                    <div className="xs-popup-img">
                                                      <Swiper
                                                        ref={swiperRef} // Attach the Swiper instance to the ref
                                                        spaceBetween={30}
                                                        centeredSlides={true}
                                                        autoplay={{
                                                          delay: 3000,
                                                          disableOnInteraction: false,
                                                        }}
                                                        pagination={{
                                                          clickable: true,
                                                        }}
                                                        navigation={false} // Disable the default navigation
                                                        className="mySwiper"
                                                      >
                                                        {filteredProjectSlider.map(
                                                          (item, index) => {
                                                            return (
                                                              <SwiperSlide>
                                                                <img
                                                                  src={`${item.Slider2}`}
                                                                  alt=""
                                                                />
                                                              </SwiperSlide>
                                                            );
                                                          }
                                                        )}
                                                      </Swiper>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <div className="col-lg-5 xs-padding-0">
                                                    <div className="xs-popup-img">
                                                      <img
                                                        src={`${filterGalleryInformation[0].MainImage}`}
                                                        alt=""
                                                      />
                                                    </div>
                                                  </div>
                                                </>
                                              )}
                                              <div className="col-lg-7">
                                                <div className="xs-popup-content">
                                                  <h2 className="hidden-title">
                                                    Project info
                                                  </h2>
                                                  <h3
                                                    style={{
                                                      margin: "20px",
                                                    }}
                                                  >
                                                    {
                                                      filterGalleryInformation[0]
                                                        .Title
                                                    }
                                                  </h3>
                                                  <div
                                                    className="row"
                                                    style={{
                                                      margin: "10px",
                                                    }}
                                                  >
                                                    <div className="col-lg-5">
                                                      <ul className="xs-popup-left-content">
                                                        <li>
                                                          <i className="icon icon-calendar-full"></i>
                                                          <label>Date</label>
                                                          <span>
                                                            {
                                                              filterGalleryInformation[0]
                                                                .Date
                                                            }
                                                          </span>
                                                        </li>
                                                        <li>
                                                          <i className="icon icon-map-marker2"></i>
                                                          <label>
                                                            Location
                                                          </label>
                                                          <span>
                                                            {
                                                              filterGalleryInformation[0]
                                                                .Location
                                                            }
                                                          </span>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                    <div className="col-lg-7">
                                                      <div className="xs-popup-right-content">
                                                        <p>
                                                          {
                                                            filterGalleryInformation[0]
                                                              .ParagraphOne
                                                          }
                                                        </p>
                                                        <blockquote>
                                                          “We must Produce What
                                                          we Eat and Eat What we
                                                          Produce.”
                                                        </blockquote>
                                                        <p>
                                                          {
                                                            filterGalleryInformation[0]
                                                              .ParagraphTwo
                                                          }
                                                        </p>

                                                        {filteredVideos &&
                                                          filteredVideos.length >
                                                            0 && (
                                                            <div className="card-deck">
                                                              {filteredVideos.map(
                                                                (
                                                                  video,
                                                                  index
                                                                ) => {
                                                                  const videoId =
                                                                    extractVideoId(
                                                                      video.VideoUrlOne
                                                                    );
                                                                  const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;

                                                                  return (
                                                                    <div
                                                                      className="card"
                                                                      key={
                                                                        index
                                                                      }
                                                                      style={
                                                                        cardStyle
                                                                      }
                                                                    >
                                                                      <div className="card-body">
                                                                        <a
                                                                          href={
                                                                            video.VideoUrlOne
                                                                          }
                                                                          target="_blank"
                                                                          rel="noopener noreferrer"
                                                                        >
                                                                          <img
                                                                            src={
                                                                              thumbnailUrl
                                                                            }
                                                                            alt={
                                                                              video.title
                                                                            }
                                                                            style={{
                                                                              maxWidth:
                                                                                "100%",
                                                                              cursor:
                                                                                "pointer",
                                                                            }}
                                                                          />
                                                                          <div
                                                                            className="play-button"
                                                                            style={
                                                                              playButtonStyle
                                                                            }
                                                                          >
                                                                            ▶
                                                                          </div>
                                                                        </a>
                                                                      </div>
                                                                    </div>
                                                                  );
                                                                }
                                                              )}
                                                            </div>
                                                          )}

                                                        <div
                                                          style={{
                                                            display: "flex",
                                                            gap: "5px",
                                                          }}
                                                        >
                                                          <Link
                                                            to={`/${filterGalleryInformation[0].ProjectUrl}`}
                                                            className="xs-btn"
                                                          >
                                                            {filterGalleryInformation[0]
                                                              .ProjectUrl &&
                                                              Object.keys(
                                                                filterGalleryInformation[0]
                                                                  .ProjectUrl
                                                              ).length > 0 && (
                                                                <>
                                                                  View Project
                                                                </>
                                                              )}
                                                          </Link>
                                                          {isMobile && (
                                                            <a
                                                              onClick={
                                                                closeModal
                                                              }
                                                              className="xs-btn"
                                                              style={{
                                                                backgroundColor:
                                                                  "#770b0b",
                                                                color: "white",
                                                              }}
                                                            >
                                                              Close
                                                            </a>
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            {!isMobile && (
                                              <>
                                                <button
                                                  title="Close (Esc)"
                                                  type="button"
                                                  className="mfp-close"
                                                  style={{
                                                    color: "red",
                                                    zIndex: "9999",
                                                  }}
                                                  onClick={closeModal}
                                                >
                                                  x
                                                </button>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                        {/*INFORMATION MODAL END*/}
                                      </>
                                    )}
                                </div>
                              </>
                            </>
                          ))}
                        </div>

                        <div className="row">
                          <div className="col-lg-12">
                            <div className="load-more-btn">
                              <a
                                className="xs-btn fill"
                                onClick={onViewMore}
                                style={{
                                  backgroundColor: "#008435",
                                  color: "white",
                                }}
                              >
                                View More
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleProject);
